import Grid from '@components/grid';
import Image from 'next/image';
import Container from '@components/ui/container';
import Text from '@components/ui/text';
import React, { ReactElement } from 'react';
import { ASSET_URL } from '@lib/constants';
import cn from 'classnames';
import { motion } from 'framer-motion';
import CMSLink from '@components/CMSLink';

// TODO props

interface DProps {
  text: string;
  description: string;
  image: any;
  isSingular: boolean;
  link: any;
}

interface RProps {
  title: string;
  items: any;
}

export function DiagramItem({
  text,
  description,
  image,
  isSingular,
  link,
}: DProps) {
  return (
    <CMSLink
      {...link}
      className={cn(
        `has-tooltip relative flex p-4 lg:p-8 flex-col justify-center items-center hover:bg-accent-1 transition-colors duration-300`,
        {
          'col-span-4': !isSingular,
          'col-span-full': isSingular,
        }
      )}
    >
      {description && (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="tooltip top-0 left-0"
        >
          {description}
        </motion.span>
      )}
      <Image
        loading="lazy"
        alt={image?.alt || ''}
        src={
          image.filename
            ? ASSET_URL + '/' + image.filename
            : '/assets/images/cerne.svg'
        }
        width={156}
        height={156}
      />

      <Text variant="heading" className="my-4">
        {text}
      </Text>
    </CMSLink>
  );
}

export function RowItem({ title, items }: RProps) {
  const isSingular = items.length > 1 ? false : true;
  return (
    <>
      <div className="col-span-full border-b border-black border-dashed py-8">
        <Text variant="mediumHeading" className="text-center">
          {title}
        </Text>
      </div>
      {items &&
        items.map((item: any) => {
          return (
            <DiagramItem
              key={`diagram--item--${item.title}`}
              text={item.title}
              description={item.description}
              isSingular={isSingular}
              image={item.image}
              link={item.link}
            />
          );
        })}
    </>
  );
}

function DiagramCluster(block: any): ReactElement {
  const { rows } = block;

  return (
    <Container>
      <Grid>
        {rows.map((row: any, i: number) => {
          const { items } = row;
          return (
            <RowItem key={`row--item--${i}`} title={row.title} items={items} />
          );
        })}
      </Grid>
    </Container>
  );
}

export default DiagramCluster;
